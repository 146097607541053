import { Container } from "common/reusecore/Layout";
import React from "react";
import styled from 'styled-components';
import techImage from 'common/assets/images/app/technology/Tech.svg'

import SectionTitle from "common/reusecore/SectionTitle";

const TechSectionWrapper = styled.div`
  padding-bottom: 80px;
  padding-top: 40px;
  background-color: #FFFCEF;
  width: 100%;
  .img_container {
    margin: auto;
    width: 100%;
    text-align: center;
  }
  h1{
    color: ${props => props.theme.primaryColor};
    font-size: 30px;
    text-align: center;
    font-family: 'Merriweather', serif;
    font-weight: 200;
  }
`




const Technologies = () => {
  return (
    <TechSectionWrapper id="TechSection">
      <Container>
       

        <SectionTitle withLine sectionPaddingBottom textColor>
          <h1>Our technology and tools</h1>
        </SectionTitle>

        <div className='img_container'>
          <img src={techImage} alt="img" />
        </div>
      </Container>
    </TechSectionWrapper>
  );
};

export default Technologies;
