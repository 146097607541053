// import particleOne from "common/assets/images/app/footer/footer-particle.png";
// import rocket from "common/assets/images/app/footer/footer_rocket.svg";
import rocket from "common/assets/images/app/footer/rocket2.png";
// import { Container, ContainerWrapper } from "common/reusecore/Layout";
import {  ContainerWrapper } from "common/reusecore/Layout/Container";
import SectionTitle from "common/reusecore/SectionTitle";
import React from "react";
import Particles from "react-particles-js";
import styled from 'styled-components';



const partParams = {
  "particles":
  {
    "number":
    {
      "value": 100,
      "density": { "enable": true, "value_area": 800 }
    }, "color": { "value": "#ffffff" },
    "shape": {
      "type": "circle", "stroke": { "width": 0, "color": "#000000" },
      "polygon": { "nb_sides": 5 },
      "image": { "src": "img/github.svg", "width": 100, "height": 100 }
    },
    "opacity": { "value": 0.5, "random": false, "anim": { "enable": false, "speed": 1, "opacity_min": 0.1, "sync": false } },
    "size": { "value": 3, "random": true, "anim": { "enable": false, "speed": 40, "size_min": 0.1, "sync": false } },
    "line_linked": { "enable": true, "distance": 150, "color": "#ffffff", "opacity": 0.4, "width": 1 },
    "move": { "enable": true, "speed": 6, "direction": "none", "random": false, "straight": false, "out_mode": "out", "bounce": false, "attract": { "enable": false, "rotateX": 600, "rotateY": 1200 } }
  },
  "interactivity":
  {
    "detect_on": "window",
    "events":
    {
      "onhover": { "enable": true, "mode": "repulse" },
      "onclick": { "enable": true, "mode": "push" }, "resize": true
    },
    "modes": {
      "grab": { "distance": 400, "line_linked": { "opacity": 1 } },
      "bubble": { "distance": 400, "size": 40, "duration": 2, "opacity": 8, "speed": 3 },
      "repulse": { "distance": 200, "duration": 0.4 }, "push": { "particles_nb": 4 },
      "remove": { "particles_nb": 2 }
    }
  }, "retina_detect": true
}

const FooterWrapper = styled.section`
  width:100%
  height: 100vh;
  background-color: ${props => props.theme.blue};
  padding-top: 20vh


  h1, h2, h3 {
      color: ${props => props.theme.white};
      
      margin: 0px;
      margin-bottom: 40px;
  }

  h1 {
      font-size: 40px
      font-family: 'Poppins', sans-serif;
  }
  h2 {
      font-size: 24px;
      line-height: 35px;
      margin-bottom: 0;
  }

`;

const PartWrapper = styled.div`
  // background-color: black;
  position: absolute;
  color: white;
  width:100%;
  top: 0px;
  height: 100vh;
  // height: 100%;
  overflow: hidden;
  transition: all 0.6s ease-in;
  opacity: ${props => props.isVisible ? 1 : 0}

`

const RocketWrapper = styled.img`
  width:100%;
  position:absolute;
  bottom:0px;
`
const StyledSpan = styled.span`
  //background-color: #FFCC80;
  //color: #455069;
  // padding: 0px 10px;
  // margin: 0px 5px;
  font-weight: bold;
`

const FooterContainer = styled(ContainerWrapper)`
  position: relative;

  h1 {
    text-shadow: 2px 2px 2px ${props => props.theme.blue};
  }
`;

const Footer = (props) => {

  const { isVisible } = props

  const showParticles = isVisible
  // const showParticles = true



  return (
    <FooterWrapper>
      <PartWrapper isVisible={isVisible}>
        {showParticles && (<Particles
          width={'100vw'}
          height={'100vh'}
          params={partParams} />
        )}
      </PartWrapper>
      <RocketWrapper src={rocket} alt="appion app landing" />

      <FooterContainer >

        <SectionTitle UniWidth="65%">
          <h2>Contact us at:</h2>
        </SectionTitle>


        <SectionTitle UniWidth="65%">
          <a href='mailto:services@kodo-dev.com'>
            <h2>services@kodo-dev.com</h2>
          </a>
        </SectionTitle>

      </FooterContainer>
    </FooterWrapper>
  );
};

export default Footer;
