import React, { useState, useCallback, useLayoutEffect, useRef } from "react";
import throttle from 'lodash.throttle'

const _process = (node, set, scale = 1, offset=0) => {
    const w = window.innerWidth;
    const h = window.innerHeight;

    // const offset = 0;
    const rect = node.getBoundingClientRect();
    // if (!rect) return;



    const viewH = h
    const centerTopPosition = (h - rect.height) / 2
    const exitTopPosition = -rect.height
    const enterTopPosition = viewH

    // console.log('centerTopPosition', centerTopPosition)
    // console.log('exitTopPosition', exitTopPosition)
    // console.log('enterTopPosition', enterTopPosition)


    const dist = Math.abs((rect.top+offset) - centerTopPosition)
    // console.log('dist', dist)


    // const scale = 0.2
    // // const percent = (inputY - yMin) / (yMax - yMin);
    const percent = Math.max(0, (dist - viewH * scale) / (0 - viewH * scale));

    // console.log('percent', percent)



    const result = {
        rect,
        percent
    }
    set(result)

    // // ((Input - InputLow) / (InputHigh - InputLow))
    // //       * (OutputHigh - OutputLow) + OutputLow;


    //       const res = (percent - 0.5) / (0.5-1) * (0-100) -100
    //       console.log(res)



    // const d = {
    //     top: rect.top,
    //     bottom: rect.bottom,
    //     y: rect.y,
    //     height: rect.height,
    // }
    // console.log('WxH',w,h)

    // console.log('d',d)
    // set(rect)

    // const centerH = rect.height /2
    // console.log('centerH',centerH)
    // console.log('top-bott',rect.top-rect.bottom +rect.y -centerH)

}

export const usePosition = (options = {}) => {
    const { liveMeasure = true, scale = 1, throttleTimeout = 1000 / 50, offset=0 } = options
    const [data, setData] = useState({});
    const [node, setNode] = useState(null);
    const ref = useCallback(node => {
        setNode(node);
    }, []);



    // const ref = useRef();
    // const node = ref.current

    useLayoutEffect(() => {
        if (node) {

            const process = throttle(() =>
                window.requestAnimationFrame(() =>
                    _process(node, setData, scale, offset)
                ), throttleTimeout);

            process();

            if (liveMeasure) {
                window.addEventListener("resize", process);
                window.addEventListener("scroll", process);

                return () => {
                    window.removeEventListener("resize", process);
                    window.removeEventListener("scroll", process);
                };
            }
        }
    }, [node]);

    return [ref, data]
}