import React from "react";
import styled from 'styled-components';


const SubFooterWrapper = styled.section`
  width:100%
  background-color: #5c3f2e;
  padding: 20px;
  & p{
    color: ${props => props.theme.white};
    text-align: center;
  }

`;


const SubFooter = (props) => {

  return (
    <SubFooterWrapper>
      <p>Kōdo - via della Moscova 15 - 20121 Milan </p>
    </SubFooterWrapper>
  );
};

export default SubFooter;
