import jkdesk from 'common/assets/images/app/main-projects/jk-desk.png';
import jkmobile from 'common/assets/images/app/main-projects/jk-mobile.png';
import jklogo from 'common/assets/images/app/main-projects/jk.svg';
import palcologo from 'common/assets/images/app/main-projects/palco-logo.svg';
import manabudesk from 'common/assets/images/app/main-projects/manabu-desk.png';
import manabumobile from 'common/assets/images/app/main-projects/manabu-mobile.png';
import manabulogo from 'common/assets/images/app/main-projects/manabu.svg';
import onponydesk from 'common/assets/images/app/main-projects/onpony-desk.png';
import onponymobile from 'common/assets/images/app/main-projects/onpony-mobile.png';
import onponylogo from 'common/assets/images/app/main-projects/onpony.svg';
import tddesk from 'common/assets/images/app/main-projects/t&d-desk.png';
import tdmobile from 'common/assets/images/app/main-projects/t&d-mobile.png';
import palcodesk from 'common/assets/images/app/main-projects/palco-desk.png';
import palcomobile from 'common/assets/images/app/main-projects/palco-mobile.png';
import tdlogo from 'common/assets/images/app/main-projects/t&d.svg';
import ygdesk from 'common/assets/images/app/main-projects/yg-desk.png';
import ygmobile from 'common/assets/images/app/main-projects/yg-mobile.png';
import yglogo from 'common/assets/images/app/main-projects/yg.svg';
import { Col, Container, Row } from "common/reusecore/Layout";
import SectionTitle from "common/reusecore/SectionTitle";
import React from "react";
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
// import {usePosition} from 'hooks/usePosition'
import { usePosition } from '../../../hooks/usePosition';

const projects = [
  {
    title: '',
    subtitle: "Make your events yours",
    description: `
    Palco is an event manager platform that allows to create and manage modular events for companies.
    The software can manage different sequential modules (webinars, surveys, chat systems modules, etc ...) through different participation flows with optional admission tool.
    Websocket technology allows real-time analysis of users and their interactions during the various steps of the event.
    `,
    logo: palcologo,
    mobile: palcomobile,
    desk: palcodesk,
    link: "https://palco.events/",
  },
  {
    title: 'Tal&Dev',
    subtitle: "Your Career Compass Powered by A.I.",
    description: `
    It is an automated mentoring and coaching platform that aims to guide the user in choosing the selected
    career according to soft skills and hard skills and to follow him in various steps using extensive use of
    machine learning algorithms.
    `,
    logo: tdlogo,
    mobile: tdmobile,
    desk: tddesk,
    link: "https://talanddev.com/",
  },
  {
    title: 'JustKnock',
    subtitle: "Looking for a JOB? Send us an IDEA",
    description: `
    It is an online platform that manages the interaction between job seekers and companies that, through their
    dedicated interface through which to publish jobCalls, can vote on the ideas of the candidates sent as
    documents, videos or audio.
  
    `,
    mobile: jkmobile,
    desk: jkdesk,
    logo: jklogo,
    link: "https://justknock.it",
  },
  {
    title: 'Yougardener',
    subtitle: "Compra piante online",
    description: `
    It is an online plant sales marketplace as well as the largest nursery in the world with more than 8.000
    different plant species and around 10.000 products.
    `,
    mobile: ygmobile,
    desk: ygdesk,
    logo: yglogo,
    link: "https://it.yougardener.com/",
  },
  {
    title: 'OnPony',
    subtitle: "Your online pony express",
    description: `
    onPony is a digital platform that connects people traveling with people looking to send something,
    allowing you to earn on travels and save on deliveries. It has been compared to the “blablacar” of goods. 
    `,
    mobile: onponymobile,
    desk: onponydesk,
    logo: onponylogo,
    link: "https://onpony.com/",
  },
  {
    title: 'Manabu',
    subtitle: "",
    description: `
    Manabù is a platform that allows you to perform online private lessons. The student can search among the
    profiles of teachers from all over Italy and find the best tutor for him. You can book the lesson, manage the
    calendar in a digital agenda that clearly highlights the availability of the teachers and the lessons of the
    student.
    `,
    mobile: manabumobile,
    desk: manabudesk,
    logo: manabulogo,
    link: null,
  },

  // {
  //   title: 'Bioclin',
  //   subtitle: "Dermonatural lab",
  //   description: `

  //   `,
  //   link: "https://talanddev.com/",
  // },
]

const MainProjectsWrapper = styled.section`
  padding-bottom: 80px;
  padding-top: 80px;
  background-color: ${props => props.theme.primaryColor};
  color: ${props => props.theme.white};
  overflow:hidden;
  h1, h2, h3, h4, h5 {
    color: inherit;
  }
  h3{
    text-transform: uppercase;
    font-weight: 600;
  }
  h5{
    font-style: italic;
    margin-bottom: 20px;
  }
  p{
    margin-bottom: 30px;
  }
`


const Image = styled.div`
  top:0
  width: ${props => props?.right ? '500px' : '145px'};
  position: absolute;
  @media (max-width: 768px){
    display: none
  }  
`


const StyledProjectItem = styled.div`
  margin-bottom: 200px;
  position: relative;
  & * {
    color: white;
  }
  .logo{
    margin-bottom: 15px;
  }
`


const AnimateImage = animated(Image)
const AnimateStyledProjectItem = animated(StyledProjectItem)

const StyledProject = (props) => {
  const { title, subtitle, description, link, logo, mobile, desk } = props;

  const [ref, { percent }] = usePosition({ scale: 0.5, offset:-100, throttle:20 })


  const { p } = useSpring({
    p: percent || 0,
  })


  const animSteps = [0, 0.35, 0.6, 1]


  return (
    <AnimateStyledProjectItem
      style={{
        opacity: p.interpolate(animSteps, [0.2, 0.2, 1, 1]),
        // left: p.interpolate(animSteps, [-300, -300, 0, 0])
      }}
      ref={ref}
      
    >
      {mobile && (
        <AnimateImage
          native
          style={{
            opacity: p.interpolate(animSteps, [0.3, 0.3, 1, 1]),
            left: p.interpolate(animSteps, [-300, -300, 0, 0])
          }}
        >
          <img src={mobile} alt="" />
        </AnimateImage>
      )}

      <Row>
        <Col style={{ margin: 'auto' }} sm={12} lg={5}>
          {logo && <img className='logo' src={logo} alt={title} />}

          <h3>{title}</h3>
          <h5>{subtitle}</h5>
          <p>{description}</p>
          {link &&
            <a href={link} target='_blank'>
              {link}</a>}
        </Col>
      </Row>

      {desk && (
        <AnimateImage
          native
          right
          style={{
            opacity: p.interpolate(animSteps, [0.3, 0.3, 1, 1]),
            right: p.interpolate(animSteps, [-600, -600, -200, -200])
          }}
        >
          <img src={desk} alt={title} />
        </AnimateImage>
      )}
    </AnimateStyledProjectItem>
  )
}


const MainProjects = () => {
  return (
    <MainProjectsWrapper id="MainProjects">
      <Container>
        <SectionTitle withLine sectionPaddingBottom inherit>
          <h1>Main Projects</h1>
        </SectionTitle>

        {projects.map((item, idx) => (

          <StyledProject
            key={idx}
            {...item}
          />

        ))}
      </Container>
    </MainProjectsWrapper>
  );
};

export default MainProjects;
