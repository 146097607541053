import { GlobalStyle } from 'common/sections/app.style';
import Footer from 'common/sections/Footer';
import SubFooter from 'common/sections/SubFooter';
import Hello from 'common/sections/Hello';
import theme from 'common/theme/app/themeStyles';
import React from "react";
import WhatWeDo from '../common/sections/WhatWeDo/index';
import Technologies from '../common/sections/Technology/index';
import MainProjects from '../common/sections/MainProjects/index';
import TrackVisibility from 'react-on-screen';
import { ThemeProvider } from 'styled-components';
import HeroTop2 from "../components/HeroTop2";
import Layout from "../components/layout";
import SEO from "../components/seo";





const IndexPage = () => (
  <ThemeProvider theme={theme}> 
    
    <GlobalStyle />
     <Layout>
       
      <SEO title="Kodo Develop" />

      <TrackVisibility partialVisibility >
        <HeroTop2> 
          <Hello/>
        </HeroTop2>
      </TrackVisibility>
      
      <WhatWeDo/>
      <Technologies/>
      <MainProjects/>

      <TrackVisibility partialVisibility offset={200}>

        <Footer />
        <SubFooter />
      </TrackVisibility>
    </Layout>
  </ThemeProvider>
)

export default IndexPage
