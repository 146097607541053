import DevelopersSvg from 'common/assets/SketchExports/Developers.svg';
import React from "react";
import styled, { keyframes } from 'styled-components';
import { Link } from "gatsby";
// import ParticleComponent from "common/reusecore/Particle";
import useWindowScroll from '@react-hook/window-scroll'
import useDimensions from "react-use-dimensions";

import logoBlack from "common/assets/images/app/logo_black.png"
import logoWhite from "common/assets/images/app/logoWhiteAlpha.svg"
import Particles from "react-particles-js";


import { Container, Row, Col } from "common/reusecore/Layout";
import SectionTitle from "common/reusecore/SectionTitle";
import Button from "common/reusecore/Button";



const HeroWrapper = styled.div`
    width:100%
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.blue};
    min-height: 100vh;
    align-items: center;
    justify-content: center;

`



const LogoWrapper = styled.img`

    width: 150px;
    height: 150px;
    margin: auto;
    z-index: 2;
    transition: all .2s ease-out;
    position: sticky;
    transform: scale(1);
    top: 10px

    ${props => props.fixButton ? `
        transform: scale(0.4);
        position: fixed;
        left: 50%;
        margin-left: -75px;
        top: -35px 
    `: `
        position: sticky;
    `}

`

const ButtonsWrapper = styled.div`
    width: 100%;
    height: 60px;
    transition: all .4s ease-out;
    // z-index: 10;

    .button_row{
        // padding: 15px;
        width: 100%;

        justify-content: center 
        align-items: center 
        display:flex;


        .spaceLogo{
            width: 100px
        }
       

        transition: all .4s ease-out;
        // z-index: 11;
        ${props => props.fixButton ? `
            position: fixed;
            top: 0px;
            // width: 1000px;
            height: 70px;
            z-index: 1;
        `: `
            // position: fixed;
            // left: 50%;
            // margin-left: -75px
            // top: 50px;
        `}





    }
        
`

const NavbarWrapper = styled.div`
    width: 100%;
    height: 80px;
    padding: 10px;
    position: fixed;
    background-color: #455069;
    z-index: 1;
    transition: all .2s ease-out;
    // border-bottom: 1px solid #e0dfe5;
    //box-shadow: 0px 0px 11px #68718a;

    ${props => props.show ? `
        opacity:1
    `: `
        opacity:0
    `}
`


const PartWrapper = styled.div`
    // background-color: black;
    position: absolute;
    color: white;
    width:100%;
    top: 0px;
    height: 200vh;
    // height: 100%;
`

const ChildrenContainer = styled.div`
    pointer-events: none;
`


const partParams = {
    "particles":
    {
        "number":
        {
            "value": 80,
            "density": { "enable": true, "value_area": 800 }
        }, "color": { "value": "#ffffff" },
        "shape": {
            "type": "circle", "stroke": { "width": 0, "color": "#000000" },
            "polygon": { "nb_sides": 5 },
            "image": { "src": "img/github.svg", "width": 100, "height": 100 }
        },
        "opacity": { "value": 0.5, "random": false, "anim": { "enable": false, "speed": 1, "opacity_min": 0.1, "sync": false } },
        "size": { "value": 3, "random": true, "anim": { "enable": false, "speed": 40, "size_min": 0.1, "sync": false } },
        "line_linked": { "enable": true, "distance": 150, "color": "#ffffff", "opacity": 0.4, "width": 1 },
        "move": { "enable": true, "speed": 6, "direction": "none", "random": false, "straight": false, "out_mode": "out", "bounce": false, "attract": { "enable": false, "rotateX": 600, "rotateY": 1200 } }
    },
    "interactivity":
    {
        "detect_on": "window",
        "events":
        {
            "onhover": { "enable": true, "mode": "repulse" },
            "onclick": { "enable": true, "mode": "push" }, "resize": true
        },
        "modes": {
            "grab": { "distance": 400, "line_linked": { "opacity": 1 } },
            "bubble": { "distance": 400, "size": 40, "duration": 2, "opacity": 8, "speed": 3 },
            "repulse": { "distance": 200, "duration": 0.4 }, "push": { "particles_nb": 4 },
            "remove": { "particles_nb": 2 }
        }
    }, "retina_detect": true
}


const HeroTop = (props) => {

    const { children,isVisible } = props;

    // const scrollY = useWindowScroll(160 /*fps*/)
    const [ref, { height }] = useDimensions();
    const [refLogo, l] = useDimensions();
    const [refButtons, b] = useDimensions();


    // const affix = (scrollY-height+100 >= 0) ? true : false
    // const affixLogo = (scrollY - 150 + 100 >= 0) ? true : false
    // const affixButton = (scrollY - 450 + 100 >= 0) ? true : false

    const affixLogo = l.left < -20
    const affixButton = b.left <= 25

    // console.log('button',b.left,affixButton)
    // const affix = affixLogo || affixButton
    const affix = affixLogo
    // console.log('logo', l, affixLogo)
    return (
        <React.Fragment>

            {isVisible &&<PartWrapper>
                <Particles
                    width={'100vw'}
                    height={'100vh'}
                    params={partParams} />
            </PartWrapper>}

            <NavbarWrapper show={affix}>

            </NavbarWrapper>

            <HeroWrapper ref={ref}>

                <div style={{ width: '100%', textAlign: 'center' }}>
                    <div ref={refLogo}></div>

                    {affixLogo && (<div style={{ height: 150, width: '100%' }}></div>)}

                    <Link to="" className="logo">
                        <LogoWrapper
                            src={logoWhite}
                            fixButton={affixLogo}
                        />
                    </Link>

                    <div style={{ height: '50px', }}></div>
                    <ChildrenContainer>
                        {children}
                    </ChildrenContainer>
                    <div style={{ height: '50px', }}></div>
                    <div ref={refButtons}></div>


                    <ButtonsWrapper fixButton={affixButton}>
                        <div className='button_row'>
                            <Link to="#WhatWeDo"  >
                                <Button zIndex={10}>What we do</Button>
                            </Link>
                            <div className='spaceLogo'></div>
                            <Link to="#MainProjects"  >
                                <Button zIndex={10}>Main projects</Button>
                            </Link>
                        </div>
                        
                    </ButtonsWrapper>
                </div>


            </HeroWrapper>



        </React.Fragment>
    )
}




export default HeroTop
